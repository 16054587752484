@font-face {
  font-family: 'Inter var';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/assets/fonts/Outfit-Light.woff2') format('woff2');
  font-named-instance: 'Light';
}

@font-face {
  font-family: 'Inter var';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('/assets/fonts/Outfit-Medium.woff2') format('woff2');
  font-named-instance: 'Medium';
}

@font-face {
  font-family: 'Archer';
  src: url('/assets/fonts/Archer-Bold.woff2') format('woff2'),
      url('/assets/fonts/Archer-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BilletStandard';
  src: url('/assets/fonts/BilletStandard.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tommy';
  src: url('/assets/fonts/Tommy.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UAMLBJersey';
  src: url('/assets/fonts/UAMLBJersey.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnitedSans';
  src: url('/assets/fonts/UnitedSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UnitedSerif';
  src: url('/assets/fonts/UnitedSerif.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FenwayPark';
  src: url('/assets/fonts/FenwayPark.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BilletStandard';
  src: url('/assets/fonts/BilletStandard.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EurostileLT';
  src: url('/assets/fonts/EurostileLT.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MachineStd';
  src: url('/assets/fonts/MachineStd.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OctinCollege';
  src: url('/assets/fonts/OctinCollege.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Script';
  src: url('/assets/fonts/Script.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Archer Book';
  src: url('/assets/fonts/Archer-Book.woff2') format('woff2'),
      url('/assets/fonts/Archer-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Archer';
  src: url('/assets/fonts/Archer-Light.woff2') format('woff2'),
      url('/assets/fonts/Archer-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Archer';
  src: url('/assets/fonts/Archer-Medium.woff2') format('woff2'),
      url('/assets/fonts/Archer-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Friz';
  src: url('/assets/fonts/FrizQuadrataBold.woff2') format('woff2'),
      url('/assets/fonts/FrizQuadrataBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Friz Quadrata TT';
  src: url('/assets/fonts/FrizQuadrataTT.woff2') format('woff2'),
      url('/assets/fonts/FrizQuadrataTT.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Impact';
  src: url('/assets/fonts/Impact.woff2') format('woff2'),
      url('/assets/fonts/Impact.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monument Extended';
  src: url('/assets/fonts/MonumentExtended-Regular.woff2') format('woff2'),
      url('/assets/fonts/MonumentExtended-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monument Extended';
  src: url('/assets/fonts/MonumentExtended-Ultrabold.woff2') format('woff2'),
      url('/assets/fonts/MonumentExtended-Ultrabold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Trajan Pro';
  src: url('/assets/fonts/TrajanPro-Bold.woff2') format('woff2'),
      url('/assets/fonts/TrajanPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Winner';
  src: url('/assets/fonts/Winner-Black.woff2') format('woff2'),
      url('/assets/fonts/Winner-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Winner';
  src: url('/assets/fonts/Winner-Bold.woff2') format('woff2'),
      url('/assets/fonts/Winner-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
