/* You can add global styles to this file, and also import other style files */
@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";

// Vex Core
@import "./@vex/styles/core";

//Material Icon
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}
.vex-style-dark .whole-change-bg{
  background: transparent !important;
  h2.black{
    color: #fff !important;
    border-right-color: #fff0;
  }
}

.vex-style-dark .whole-change-bg-template{
  background: transparent !important;
  h2.black{
    color: #fff !important;
    border-right-color: #fff0;
  }
}
.vex-style-dark .text-primary{
    color: whitesmoke !important;
}


.vex-style-dark .bg-selected{
  background-color : rgb(24 90 124 / 64%) !important;
}

.mt-auto{
  margin-top: auto;
}

body {
  margin: 0;
}

main {
  height: calc(100% - 64px);
  max-height: calc(100% - 64px);
  ;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 14px;
}

.mx-auto{
  margin:0 auto
}
canvas {
  display: inline !important;
}

vex-page-layout-content.vex-page-layout-content.container.td-outer-main{
  .mat-tab-body-content {
    height: 100%;
    overflow: unset;
    width: 100%;
    padding: 12px;
  }
}
.itemSelected {
  background: #eaeaea;
  opacity: 0.6;

}

vex-page-layout {
  height: 100%;
}

.td-date-range-main{
  height: 49px;
  max-height: 49px;
  .mat-date-range-input-start-wrapper{
    width: 100%;
    max-width: 100%;
  }
}
.td-div-for-report{
  .td-hover-shadow-card {
    margin: 10px;
  }
}

.second-td-roster-main{
  .roster-school-selecter {
      .mat-form-field-appearance-outline .mat-form-field-infix{
        padding: 8px 0 !important;
      }
  }
}

.error.mat-snack-bar-container {
  color: antiquewhite;
  background: #D31027;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #EA384D, #D31027);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #EA384D, #D31027);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.success.mat-snack-bar-container {
  color: antiquewhite;
  background: #1D976C;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #4ad67e, #118b60);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #4ad67e, #118b60);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.warning.mat-snack-bar-container {
  color: antiquewhite;
  background: #FF8008;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #FFC837, #ce8904);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #FFC837, #ce8904);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}


// Start loading css
.vex-splash-screen {
  .wrapper {
    width: 200px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .circle {
      width: 20px;
      height: 20px;
      position: absolute;
      border-radius: 50%;
      background-color: #fff;
      left: 15%;
      transform-origin: 50%;
      animation: circle .7s alternate infinite ease;

      &:nth-child(2) {
        left: 45%;
        animation-delay: .2s;
      }

      &:nth-child(3) {
        left: auto;
        right: 15%;
        animation-delay: .3s;
      }

      @keyframes circle {
        0% {
          top: 60px;
          height: 5px;
          border-radius: 50px 50px 25px 25px;
          transform: scaleX(1.7);
        }

        40% {
          height: 20px;
          border-radius: 50%;
          transform: scaleX(1);
        }

        100% {
          top: 0%;
        }
      }
    }

    .shadow {
      width: 20px;
      height: 4px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, .5);
      position: absolute;
      top: 62px;
      transform-origin: 50%;
      z-index: -1;
      left: 15%;
      filter: blur(1px);
      animation: shadow .5s alternate infinite ease;

      &:nth-child(4) {
        left: 45%;
        animation-delay: .2s
      }

      &:nth-child(5) {
        left: auto;
        right: 15%;
        animation-delay: .3s;
      }

      @keyframes shadow {
        0% {
          transform: scaleX(1.5);
        }

        40% {
          transform: scaleX(1);
          opacity: .7;
        }

        100% {
          transform: scaleX(.2);
          opacity: .4;
        }
      }
    }

    span {
      position: absolute;
      top: 105px;
      font-size: 26px;
      letter-spacing: 5px;
      line-height: normal;
      color: #cbcbcb;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
    }
  }
}

@media screen and (min-width:320px) and (max-width:520px) {
  .td-roster-main{
    .px-gutter{
      h1.subheading-2{padding-right: 0 !important;}
    }
  }
}
@media screen and (min-width:280px) and (max-width:768px) {
  .mytd-popupclass {
    max-width: 100vw !important;
    width: 100vw;
    height: 75vh;
  }
}


@media screen and (min-width:768px) and (max-width:1180px) {
  .mytd-popupclass {
    max-width: 100vw !important;
    width: 100vw;
    height: 75vh;
  }
}

@media screen and (min-width:1240px) and (max-width:1440px) {
  .mytd-popupclass {
    max-width: 80vw !important;
    width: 80vw;
    height: 80vh;
  }
}

@media screen and (min-width:1440px) and (max-width:1640px) {
  .mytd-popupclass {
    max-width: 80vw !important;
    width: 80vw;
    height: 80vh;
  }
}

@media screen and (min-width:1640px) {
  .mytd-popupclass {
    max-width: 80vw !important;
    width: 80vw;
    height: 80vh;
  }
}

::ng-deep .successSnackbar .mat-simple-snackbar {
  color: rgb(147, 252, 147) !important;

}

// Product Popup

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 22px;
  height: 22px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #185A7C;
  animation: spinner .4s linear infinite;
}

.spinnerForBlock:before {
  content: '';
  box-sizing: border-box;
  position: relative;
  display: block;
  top: 50%;
  left: 30%;
  width: 22px;
  height: 22px;
  margin-top: 2px;
  margin-right: 80px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #185A7C;
  animation: spinner .4s linear infinite;
}


.mat-card{
  border-radius: 6px !important;
}

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #0e1016;
  opacity: 0.5;
  z-index: 10000;
}
.loading-overlay-block {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  // background: #fff;
  z-index: 10000;
}
.vex-style-dark .loading-overlay-block{
  background: none !important;
}

// Spinner loading

$cl-black: #111;
$cl-white: #f1f1f1;

$cl-true-white: #fff;
$cl-olympic-blue: #0b52a4;
$cl-olympic-blue-lighter: #589dec;
$fo-type-family: sans-serif;
$fo-type-size: 1em;


.loader-container {
  width: 92px;
  height: 38px;
  float: left;
}

.abs-center {
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@keyframes spin {
 from {
    -webkit-transform: rotate(0deg);
   	-moz-transform: rotate(0deg);
   	-o-transform: rotate(0deg);
   	-ms-transform: rotate(0deg);
   	transform: rotate(0deg);
 }
 to {
    -webkit-transform: rotate(360deg);
   	-moz-transform: rotate(360deg);
   	-o-transform: rotate(360deg);
   	-ms-transform: rotate(360deg);
   	transform: rotate(360deg);
 }
}

@-moz-keyframes spin {
 from {
   -moz-transform: rotate(0deg);
   transform: rotate(0deg);
 }
 to {
   -moz-transform: rotate(360deg);
   transform: rotate(360deg);
 }
}

@-webkit-keyframes spin {
 from {
   -webkit-transform: rotate(0deg);
   transform: rotate(0deg);
 }
 to {
   -webkit-transform: rotate(360deg);
   transform: rotate(360deg);
 }
}

@-ms-keyframes spin {
 from {
   -ms-transform: rotate(0deg);
   transform: rotate(0deg);
 }
 to {
   -ms-transform: rotate(360deg);
   transform: rotate(360deg);
 }
}

@-o-keyframes spin {
 from {
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
 }
 to {
   -o-transform: rotate(360deg);
   transform: rotate(360deg);
 }
}

app-player-signup .mat-horizontal-content-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.olympicloader {
  position: relative;
  .loader-circle {
    width: 28px;
    height: 28px;
    border: 3px solid $cl-olympic-blue-lighter;
    border-radius: 50%;
    -webkit-animation: spin 400ms infinite linear;
    -moz-animation: spin 400ms infinite linear;
    -ms-animation: spin 400ms infinite linear;
    -o-animation: spin 400ms infinite linear;
    animation: spin 400ms infinite linear;
    float: left;
    &.first {
      position: absolute;
      top: 0;
      left: 0;
      border-top-color: $cl-true-white;
    }
    &.second {
      position: absolute;
      top: 0;
      left: 32px;
      border-right-color: $cl-true-white;
    }
    &.third {
      position: absolute;
      top: 0;
      left: 64px;
      border-bottom-color: $cl-true-white;
    }
    &.fourth {
      position: absolute;
      top: 10px;
      left: 16px;
      border-left-color: $cl-true-white;
    }
    &.fifth {
      position: absolute;
      top: 10px;
      left: 48px;
      border-right-color: $cl-true-white;
    }
  }
}

@keyframes componentLoader {
  to {
    transform: rotate(360deg);
  }
}

.componentLoader:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 42px;
  height: 42px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  border-top-color: #185A7C;
  animation: spinner .4s linear infinite;
  z-index:400;
}

.custom-modalbox {
  color: #000;margin: 0 15px;


  mat-dialog-container {
    border-radius: 10px;

    .amount{
      font-size: 28px;
      margin-bottom: 5px;
    }

    .border-blue{
      border-color: rgb(37,99,235) !important;
    }
    mat-form-field{
      @media (max-width:480px){
        margin-bottom: -1em;
      }

    }
  }

  &.disclosure{
    @media (max-width:767px) {
    max-width: 100% !important;
    .mat-dialog-container{
            padding: 0;
        margin: 0;

        .mat-dialog-content{
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

mat-dialog-container{
  margin: 0 10px;
}


.font27{
  font-size: 2.7rem;
  @media (max-width:1199px){
    font-size: 2rem;
}
  @media (max-width:991px){
      font-size: 1.25rem;
  }

}

.vex-style-dark .td-analytics-main-bg{
  .rounded-full{
    color: #fff;
  }
}
.analytics{
  .donut{
      .card{
          background-color: transparent;
          box-shadow: none;
      }
  }
  .donut2{
    .card{
      background-color: transparent;
      box-shadow: none;
      .apexcharts-canvas{
        svg{
          .apexcharts-legend{
            // display: none;
            opacity: 0;
            visibility: hidden;
          }
        }
      }
  }

  }
}

.vex-style-dark {
  .sale-one{
    background: #868d93 !important;
  }
  .sale-two{
    background: #b5ab9d !important;
  }
  .sale-three{
    background: #758ca3 !important;
  }
  .color-black{
    color: #0a1929 !important;
  }
  .royalties:after, .sales:after,.license:after{
    filter: opacity(0.1) contrast(0) !important;
  }
  .color-white{
    color: #cdcdcd !important;
  }
  .dark-bg{
    background-color: transparent !important;
  }
  .dark-white-bg{
    background-color: #fff !important;
  }
  .dark-border{
    border-color: #fff !important;
  }
  .darkhover:hover{
    background: #273945  !important;
  }
  .darkselected{
    background: #273945  !important;
  }
  .darkbar{
    background-color: #1a202d !important;
  }
}

.btn-small{
  line-height: 26px !important;
    font-size: 12px !important;
    min-width: 1%;
}

.custom-header {
  .mat-mdc-tab-header {
    max-width: 45rem;
  }
}

.td-notification-popup.mat-mdc-menu-panel {
  max-width: 315px !important;
  min-width: 315px !important;

  .mat-mdc-menu-content {
    padding: 0 !important;
  }
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

.w-full-list-item {
  .mdc-list-item__primary-text {
    width: 100%;
  }
}

.libre-barcode-39-regular {
  font-family: "Libre Barcode 39", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: 6rem;
  line-height: 1;
}

